#not-found {
    .error-body h1 {
        font-size: 210px;
        font-weight: 900;
        text-shadow: 4px 4px 0 #ffffff, 6px 6px 0 #263238;
        line-height: 210px;
    }

    .error-box {
        height: 100%;
        position: fixed;
        width: 100%;
    }

    .error-body {
        padding-top: 5%;
    }

    .m-b-30 {
        margin-bottom: 30px;
    }

    .m-t-30 {
        margin-top: 30px;
    }

    .btn-to-main-page {
        color: white;
        background-color: $primary;
        border-color: $primary;
        -webkit-transition: border-color .5s ease-in-out, -webkit-box-shadow .5s ease-in-out;
        transition: border-color .5s ease-in-out, -webkit-box-shadow .5s ease-in-out;
        transition: border-color .5s ease-in-out, box-shadow .5s ease-in-out;
        transition: border-color .5s ease-in-out, box-shadow .5s ease-in-out, -webkit-box-shadow .5s ease-in-out;
    }

    .btn-to-main-page:hover {
        color: white;
        border-color:$primary;
        -webkit-box-shadow: 0 0 8px 0 $primary;
        box-shadow: 0 0 8px 0 $primary;
    }

    .btn-rounded {
        border-radius: 60px;
        padding: 7px 18px;
    }

    .waves-effect {
        position: relative;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        vertical-align: middle;
        z-index: 1;
        will-change: opacity, transform;
        -webkit-transition: all 0.1s ease-out;
        -moz-transition: all 0.1s ease-out;
        -o-transition: all 0.1s ease-out;
        -ms-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

    .footer {
        bottom: 0;
        color: #67757c;
        left: 0px;
        padding: 17px 15px;
        position: absolute;
        right: 0;
        border-top: 1px solid $primary;
        background: #ffffff;
    }

    .error-box .footer {
        width: 100%;
        left: 0px;
        right: 0px;
    }
}

@media (max-width: 768px) {
    .error-body h1 {
        font-size: 150px;
    }
}