$primary:#8FAB46; // primary
$primaryHover:rgba(143, 171, 70, 0.75); // primary при наведені + фокус

$fs: 20;


@mixin font($font_name, $file_name, $weight, $style) {

    @font-face {
        font-family: $font_name;
        font-display: swap;
        src: url("../fonts/#{$file_name}.woff") format("woff"),
        url("../fonts/#{$file_name}.woff2") format("woff2");
        font-weight: #{$weight};
        font-style: #{$style};
    }
}

@mixin font-size($sizeValue) {
    font-size: ($sizeValue / $fs) + rem;
}

@import "libs/owl.theme.default.min";
@import "libs/owl.carousel.min";
@import "libs/bootstrap";

@import "fonts";
@import "page-scrollbar";
@import "404";

body {
    overflow-x: hidden;
    font-weight: 400;
    font-size: $fs + px;
    font-family: 'FontsFree-Net-SFProDisplay';
}

.container-fluid, 
.container-lg, 
.container-md, 
.container-sm, 
.container-xl{
    max-width: 1700px;
}

html {
    font-size: $fs + px;
}

button {

    &:hover,
    &:focus {
        border-color: $primary;
        -webkit-box-shadow: 0 0 8px 0 $primary;
        box-shadow: 0 0 8px 0 $primary;
    }
}

.btn,
.btn.focus,
.btn:focus,
.btn:active,
.btn:visited,
.input,
.input:focus,
div,
div:focus,
textarea,
textarea:focus,
.form-control:focus,
.form-control,
.form-control:active {
    outline: none;
    box-shadow: none;
    border: none;
}

.sitemap {
    margin-top: 3rem;

    &__title {
        @include font-size(48);
        color: $primaryHover;
        text-align: center;
        font-weight: bold;
    }

    &__subtitle {
        @include font-size(25);
    }


    &__list-group {
        margin-top: 1.5rem;
    }

    &__list-item {
        list-style: decimal;
        color: $primary;
    }

    &__list-link {
        color: $primary;
        text-decoration: none;
        transition: all 0.35 linear;

        &:hover {
            color: $primary;
        }
    }
}

.page-btn {
    font-family: 'sf-ui-display';
    font-weight: 500;
    background: $primary 0% 0% no-repeat padding-box;
    color: white;
    border-radius: 32px;
    padding: 0.35rem 1.35rem;
    transition: all 0.25s linear;
    line-height: 1.45;
    @include font-size(18);

    &:hover {
        color: white;
        background-color: $primaryHover;
    }

}

.title {
    @include font-size(55);
}


.navbar {
    padding: 0 0 5rem 0;

    &-logo {
        max-width: 222px;
        height: auto;
    }

    .navbar-nav .nav-link {
        color: white;
        transition: all 0.25s linear;

        &:hover {
            color: $primary;
        }
    }


    .navbar-nav .active>.nav-link,
    .navbar-nav .nav-link.active,
    .navbar-nav .nav-link.show,
    .navbar-nav .show>.nav-link {
        color: $primary;
    }

    .nav-lang__country {
        &:hover {
            text-decoration: none;
        }
    }

    .nav-lang__country.first {
        margin-right: 0.35rem;
    }

    .nav-lang__country-img {
        max-width: 35px;
        height: auto;
    }

    .page-btn {
        padding: 0.25rem 1.25rem;
        @include font-size(18);
    }
}

.navbar-white{
    background-color: white;
    padding: 0;
    border-bottom:1px solid #CFCFCF;

    .nav-item {
        .nav-link{
            color: #2F3D6F;
        }
    }
}

// .btn{
//     font-family: "sf-ui-display";
//     font-weight: 500;
// }


.header {
    background-color: #2F3D6F;
    min-height: 100vh;
    height: 850px;

    
    &-home {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-wrapper {
        display: flex;
        align-items: center;

    }

    &-title {
        color: white;
        max-width: 712px;
        font-size: 2.7rem;

    }

    &-text {
        max-width: 770px;
        color: white;
        margin-bottom: 3rem;
    }

    &-img {
        max-width: 700px;
        height: auto;
    }

    &-social {
        margin: 1.5rem 0;
    }

    &-social__link {
        &:not(:last-child) {
            margin-right: 1.5rem;
        }
    }
}

.header-template{
    &__title{
        color: #FFFFFF;
        @include font-size(70);
        font-weight: bold;
        line-height: 65px;
        background-color: #2F3D6F;
        margin-top: -10%;
        padding:0.75rem 2rem;
        max-width: 1245px;
    }

    &__wrapper{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        height: 100vh;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.header-whatwedo{
    &__wrapper{
        background-image: url('../img/products/whatwedo.png');
    }
}

.header-creditproduct{
    &__wrapper{
        background-image: url('../img/products/credit-product.png');
    }
}

.header-services{
    &__wrapper{
        background-image: url('../img/products/services.png');
    }
}
.header-paymentservice{
    &__wrapper{
        background-image: url('../img/products/payment-service.png');
    }
}

.header-whoweare{
    &__wrapper{
        background-image: url('../img/products/who-we-are.png');
    }
}

.information-template{

    p{
        color: #878787;
        @include font-size(18);
        line-height: 20px;
    }



    &__container{
        background-color: white;
        margin-top: -7.5rem;
        padding: 5.5rem 2.5rem 2.5rem 2.5rem;
        position: relative;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;

            height: 5px;
            background: linear-gradient(to right,#2F3D6F 0%,#2F3D6F 33%,#8FAB46 33%,#8FAB46 66%, #fff 66%,#fff 100%);
        }
    }

    &__img{
        max-width: 100%;
        height: auto;
    }

    &__row{
        background-color: #2F3D6F;
        padding: 2.5rem 2.5rem 2.5rem 2.5rem;
        color: white;

        p{
            color: white
        }

        &-title{
            font-weight: bold;
            line-height: 43px;
            font-size:1.75rem;
            color: white;
            margin-bottom: 3rem;
        }

        &-text{    
            max-width: 668px;
            line-height: 43px !important;
            font-size: 1.75rem !important;
            color: white !important;
        }
    }

    &__title{
        @include font-size(40);
        color: #2F3D6F;
        letter-spacing: 0px;
        font-weight: bold;
        text-align: center;
    }

    &__text{        
        font-size: 2rem !important;
        line-height: 43px !important;
        text-align: center !important;
        color: #878787 !important;
        max-width: 1000px;
        margin: 0 auto;
    }
}


.services {
    padding: 4.5rem 0;

    &-list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    &-item {
        padding: 20px;
        width: 150px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    &-title {
        text-align: center;
        color: #2F3D6F;
        margin-bottom: 5rem;
    }

    &-img {
        max-width: 60px;
        height: auto;

        display: block;
        margin: 0 auto;
    }

    &-subtitle {
        margin-top: 0.75rem;
        color: #878787;
        @include font-size(20);
    }



}

.raise {
    padding-top: 7rem;
    padding-bottom: 3rem;

    &-title {
        text-align: center;
        color: #2F3D6F;
        max-width: 825px;
        margin: 0 auto 3.5rem auto;
    }

    &-subtitle {
        font-family: 'sf-ui-display';
        font-weight: 700;
        min-height: 130px;
        @include font-size(23);
        margin-bottom: 2rem;
        padding-top: 3rem;
    }

    &-text, p {        
        font-family: 'sf-ui-display';
        font-weight: 500;
        color: #878787;
        @include font-size(14);
    }

    &-col {
        display: flex;
        align-items: stretch;

    }

    &-card {
        text-align: center;
        padding: 5rem 1rem 2rem 1rem;
        background: #FBFBFB 0% 0% no-repeat padding-box;
        border: 2px solid #CFCFCF;
        color: #878787;

        img{
            height: 70px;
        }
    }

}

.raise-second {
    background-color: #2F3D6F;

    .raise-card {
        background: #2F3D6F 0% 0% no-repeat padding-box;
        border: 2px solid white;
        color: white;
    }

    .raise-title,
    p {
        color: white;
    }
}

.raise-third{
    .raise-title{
        max-width: 100%;
    }
    .raise-card{
        background-color: white;
    }
}

.client {
    background: #FBFBFB 0% 0% no-repeat padding-box;
    padding: 5rem 0 3rem 0;

    &-col {
        padding: 1rem 2rem 1rem 2rem;

        span {
            background-color: #2F3D6F;
            color: white;
            font-weight: bold;
            padding-left: 0.5rem;
            padding: 0.5rem;
            line-height: 1;
        }

        span.first {
            @include font-size(45);
            padding-bottom: 1px;
        }

        span.second {
            @include font-size(60);
            padding-top: 1px;
        }
    }

    .col-first {
        background-image: url('../img/home/01.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        min-height: 700px;
    }




    &-title {
        font-family: 'sf-ui-display';
        font-weight: 500;
        max-width: 670px;
        @include font-size(45);
        color: #2F3D6F;
    }

    &-text { 
        font-family: 'sf-ui-display';   
        font-weight: 400;
        line-height: 24px;
        margin-top: 3rem;
        max-width: 670px;
        @include font-size(17.5);
        color: #2F3D6F;
    }
}


.client-second {
    background-color: #2F3D6F;

    .col-first {
        background-image: url('../img/home/2.png');
    }

    .client-text,
    .client-title {
        color: white;
    }

    .client-text {
        max-width: 730px;
        line-height: 25px;
        font-size: 0.95rem;
    }
}

.client-third {
    .col-first {
        background-image: url('../img/home/3.png');
    }

    .row {
        position: relative;
        z-index: 2;
    }
    .client-text {
        font-size: 0.95rem;
    }

    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 7.5rem;
        background-color: #fff;
    }
}

.uaction{
    margin-top: 5rem;
    margin-bottom: 5rem;
    font-family: 'sf-ui-display';

    &-text{
        line-height: 47px;
        @include font-size(47);
        color: #2F3D6F;    
        max-width: 684px;
        font-weight: 400;
    }

    &-btn{
        font-weight: 500;
        @include font-size(36);
        width: 350px;
    }
}


.news{
    background-color: #FBFBFB;
    padding-bottom: 5rem;
    padding-top: 3rem;

    &-title{
        line-height: 47px;
        @include font-size(50);
        color: #2F3D6F;
        text-align: center;
        margin-bottom: 5rem;
    }

    &-text{
        color: #2F3D6F;
        @include font-size(25);
        line-height: 30px;
        margin-top: 0.75rem;
        margin-bottom: 1rem;

        &:hover{
            text-decoration: none;
        }
    }

    &-row{
        padding-left: 4.5rem;
        padding-right: 0;
    }
}

.owl-carousel{
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .item{
        width: 100%;
        min-height: 350px;
        border: 2px solid black;
        padding: 1rem;
    }

    .owl-nav{
        button{
            margin-top: -7.5px !important;
        }
    }

    .owl-dots{
        justify-content: flex-end;
    }

    .owl-nav, .owl-dots{
        width: 50%;
        display: flex;
        button{            
            &:hover{
                text-decoration: none !important;
                background-color: transparent !important;
                border-color: transparent !important;
                outline: none !important;
                box-shadow: none !important;
                color:#000 !important   ;
            }
            span{
                vertical-align: super;
            }
        }
    }

    .owl-dots.disabled, .owl-nav.disabled{
        display: block;
    }

}

.footer{
    background-color: #161616;
    padding:3rem 0;


    .col-img .footer-img{
        max-width: 100%;
        height: auto;
    }

    &-img{
        padding:1rem 1rem;
        max-width: 100%;
        height: auto;
    }

    &-col{
        text-align: right;
    }

    &-title{
        color: #8FAB46;
        @include font-size(20);
        margin-bottom: 2.5rem;
    }

    &-text{
        color: white;
        @include font-size(12);
    }
}


.doc{
    margin-top: 10rem;
    margin-bottom: 7.5rem;

    &-title{
        color:#2F3D6F;
        text-align: center;
        @include font-size(70);
    }

    &-subtitle{
        color: #2F3D6F;
        @include font-size(30);
        margin-bottom: 0;
        // line-height: 2;
    }

    &-card{
        display: flex;
        justify-content: space-between;
        align-items: center;

        border: 1px solid #CFCFCF;
        padding: 2.5rem;
        margin-top: 2.5rem;
    }

    &-btn{
        border: 2px solid $primary;
        color: $primary;
        font-weight: 500;
        @include font-size(25);
        transition: all 0.4s linear;

        &:hover{
            background-color: $primary;
            color:white;
        }

    }
}



#greeningPay{
    ::-webkit-scrollbar-thumb {
        background:  #0099CC;
    }
}
.gr{
    ::-moz-selection {
        color: white;
        background: #0099CC;
    }

    ::selection {
        color: white;
        background: #0099CC;
    }

    hr{
        border-top-color: #DADADA;
        border-top-width: 2px;
    }

    display: flex;  
    align-items: center;
    padding: 2rem 0;
    
    button {
        &:hover,
        &:focus {
            border-color: #0099CC;
            -webkit-box-shadow: 0 0 8px 0 #0099CC;
            box-shadow: 0 0 8px 0 #0099CC;
        }
    }

    .gr-btn{
        border:2px solid #0099CC;
        @include font-size(30);
        min-width: 250px;
        color: #0099CC;
        font-weight: 600;
    }

    &-col__first{
        &-wrapper{
            height:calc(100vh - 2rem);
            max-height: calc(1072px - 2rem);
        }
        .gr-title{
            margin-top: 7.5rem;
            margin-bottom: 2.5rem;
            @include font-size(45);
        }



        .gr-title,
        .gr-btn{
            font-weight: bold;
            color:#0099CC;
        }
        .copyright{
            margin-top: 5rem;
            color:#DADADA;
        }
    }
    &-col__second{
        overflow-y: scroll;
        height: calc(100vh - 2rem);
        &::-webkit-scrollbar{
            width: 7.5px;
        }
    }

    .qr-info__title{
        text-align: center;
        color: #0099CC;
        @include font-size(30);
        font-weight: bold;
        margin-top: 2rem;
    }
    .qr{
        background-color: #0099CC;
        color: white;

        

        &-title{
            @include font-size(30);
        }

        &-text{
            @include font-size(20);
            margin-top: 2.5rem;
            margin-bottom: 4rem;
        }

        &-box{
            padding:2rem;
            text-align: center;
            max-width: 700px;
            margin: 0 auto;
        }

        img{
            border:1px solid #dcdcdc;
            background-color: white;
            padding: 10px;
            border-radius: 10px;
            max-width: 200px;
            height: auto;
        }

        &-amount{
            @include font-size(30);
            text-align: center;
            margin-top: 1rem;
        }

        &-btn{
            color: #0099CC;
            background-color: white;
            padding-left: 1rem;
            padding-right: 1rem;
            min-width: 250px;
        }
    }
    .qr-info{        
        border:2px solid #DADADA;

        &__box{
            padding:2rem;
            text-align: center;
            max-width: 700px;
            margin: 0 auto;
        }


        &__btn{
            background-color: #0099CC;
            color: white;
            min-width: 250px;
        }
    }
    .qr-btn, .qr-info__btn{
        border-radius: 16px;
        padding:0.6rem 1.25rem;
    }
    .gr-btn{
        border-radius: 0;
        padding:0.6rem 1.25rem;
    }
}











@media(max-width:1919.99px) {}

@media(max-width:1490.99px) {
    .header-title{
        font-size: 2.3rem;
    }
}

@media(max-width:1439.99px) {}



@media(max-width: 1275px){
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .25rem;
        padding-left: .25rem;
    }

    .header .navbar .page-btn{
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    .uaction-text{
        font-size: 2.1rem;
    }
}

@media(max-width:1199.98px) {
    .navbar-expand-xl>.container, 
    .navbar-expand-xl>.container-fluid, 
    .navbar-expand-xl>.container-lg, 
    .navbar-expand-xl>.container-md, 
    .navbar-expand-xl>.container-sm, 
    .navbar-expand-xl>.container-xl{
        padding: 0 110px;
    }
    .header .navbar .page-btn{
        margin-left: 0rem !important;
        margin-right: 0rem !important;
    }
    .news-row{
        padding-left: 0;
    }
    .gr-col__second{
        overflow: auto;
        height: auto;
    }
    .gr-col__first-wrapper{
        height: auto;
        min-height: auto;
    }
    .gr-img{
        max-width: 65%;
        height: auto;
    }
    .gr{text-align: center;}


    .qr{
        position: relative;
        z-index: 1;
        &::before{
            z-index: -1;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 350px;
            width: 100%;
            background-color:white;
        }

        .qr-amount{
            color: #09c;
            font-weight: 600;
            font-size: 2.1rem;
        }

        .qr-btn{    
            border-radius: 14px;
            background-color: #09c;
            color:white;
            padding-top: 0.9rem;
            padding-bottom: 0.9rem;
        }
    }
    .qr-info__btn{    
        border-radius: 14px;
        background-color: #09c;
        color:white;
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
    }
    .gr-col__first .gr-title{
        font-size: 2rem;
    }
    .gr-col__first .copyright{
        margin-top: 0;
        display: none;
    }
    .gr .gr-btn{
        font-size: 1rem;
        padding: 1rem;
    }
    
}

@media(max-width:991.98px) {
    html{
        font-size: 17.5px;
    }
    .header{
        height: auto;
    }
    .raise-col{
        margin: 1rem 0;
    }
    .client-third:after{
        content: none;
    }
    .footer-col{
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        text-align: center;
    }
    .header{
        img{
            max-width: 600px;
            margin: 0 auto;
        }
        .header-social{
            max-width: 300px;
            margin:3rem auto;
            display: flex;
            justify-content: space-around;
        }
    }
    .information-template__row p{
        margin-top: 3rem;
    }
    .information-template__row img{
        margin: 0 auto;
        display: block;
    }
    .information-template{
        text-align: center;
    }
}

@media(max-width:767.98px) {
    html{
        font-size: 15px;
    }
        .header{
            img{
                max-width: 100%;
                margin: 0 auto;
            }
        }
        .footer-text{
            max-width: 85%;
            margin: 0 auto;
        }
}

@media(max-width:575.98px) {
    .container-fluid, .container-lg, 
    .container-md, 
    .container-sm, .container-xl,
    .navbar-expand-xl>.container, 
    .navbar-expand-xl>.container-fluid, 
    .navbar-expand-xl>.container-lg, 
    .navbar-expand-xl>.container-md, 
    .navbar-expand-xl>.container-sm, 
    .navbar-expand-xl>.container-xl{
        padding-left: 15px;
        padding-right: 15px;
    }

    .services-item{
        padding: 10px;
        width: 78px;
    }
    .services-img{
        max-width: 40px;
    }
    .title{
        font-size: 2.55rem;
    }
    .services-title{
        margin-bottom: 3rem;
    }
    .raise{
        padding-top: 3rem;
    }
    .services{
        padding-bottom: 3rem;
    }
    .raise-first{
        background-color: #FBFBFB;
    }
    .raise-card{    
        max-width: 237px;
        margin: 0 auto;
        padding-top: 2.5rem;
    }
    .raise-subtitle{
        min-height: auto;
    }
    .raise p, .raise-text{
        font-size: 11px;
    }
    .services-subtitle{
        font-size: 1.1rem;    
    }
    .raise-second .raise-title{
        max-width: 300px;
    }
    .raise-subtitle{
        margin-bottom: 1rem;
        padding-top: 1rem;
    }
    .page-btn{
        font-size: 13px;
    }
    .raise-col {
        margin: .35rem 0;
    }
    .header .navbar-brand img{
        max-width: 142px;
    }
    .header img{
        padding:0 0.15rem;
    }
    .client .col-first{
        min-height: 329px;
        width: 75%;
    }
    .client-col{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span{
            position: absolute;
            right: -50px;
        }
        span.second{
            margin-top: 35px;
        }
    }
    .client-title{
        margin-bottom: 3rem;
        text-align: center;
    }

    .client-text,
    .client-second .client-text{
        text-align: center;    
        line-height: 17px;    
        max-width: 85%;
        margin: 2.5rem auto;
    }
    .client-second .col-first{
        margin-left: auto;
        span{
            right: auto;
            left: -50px;
        }
    }
    .client-third .col-first{
        margin: 0;
        span{
            left: auto !important;
            right: -50px !important;
        }
    }
    .uaction-btn{
        padding: 7px 45px;
        max-width: 165px;
        margin: 0 auto;
    }
    .uaction-text{
        line-height: 35px;
        text-align: center;
    }

}

@media (max-width: 375px) {
    html{
        font-size: 12.5px;
    }
}