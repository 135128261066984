::-moz-selection {
    color: white;
    background: $primary;
}

::selection {
    color: white;
    background: $primary;
}

html {
    scrollbar-color: $primary;
    scrollbar-width: 15px;
    color: #fff;
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px
}

::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 15px;
    -webkit-box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .25), inset -2px -2px 2px rgba(0, 0, 0, .25);
}

::-webkit-scrollbar-track {
    background: #fff;
}